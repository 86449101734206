import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => {
  if (typeof window === 'undefined') return null

  return (
    <Layout articlelevel={100}>
      <h1
        style={{
          padding: '100px 0',
          textAlign: 'center',
          fontSize: '1rem',
          background: '#222',
          color: 'white',
        }}
        className="flex"
      >
        Tyvärr, sidan hittades inte
      </h1>
    </Layout>
  )
}

export default NotFoundPage
